/** @jsx jsx */
import React from 'react'
import { Styled, IconButton, Badge, jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { Swiper, A11y, Lazy } from 'swiper'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Lightbox } from 'react-modal-image'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import Footer from '../components/footer'
import { ImageFluid } from '../utils/image'
import { smallBreakpoint, useCurrentBreakpoint, useWindowSize } from '../utils/media'
import MdxField from '../utils/mdx'
import { LeftArrowIcon, RightArrowIcon } from '../components/icons'
import Breadcrumb from '../components/breadcrumb'

import 'swiper/swiper.min.css'
import 'swiper/components/lazy/lazy.min.css'
import 'swiper/components/a11y/a11y.min.css'
import { SocialShare } from '../components/share'

const reducer = (state, action) => ({
  ...state,
  ...action
})

const getMaxWidth = (sHeight, aspectRatio) => {
  let orientation

  if (aspectRatio >= 1.2) orientation = 'landscape'
  if (aspectRatio <= 0.8) orientation = 'portrait'
  if (aspectRatio > 0.8 && aspectRatio < 1.2) orientation = 'square'

  if (sHeight <= 500 && orientation === 'portrait') {
    return '150px'
  } else if (sHeight <= 500 && orientation === 'landscape') {
    return '300px'
  } else if (sHeight <= 500 && orientation === 'square') {
    return '250px'
  }

  if (sHeight <= 600 && orientation === 'portrait') {
    return '200px'
  } else if (sHeight <= 600 && orientation === 'landscape') {
    return '400px'
  } else if (sHeight <= 600 && orientation === 'square') {
    return '350px'
  }

  if (sHeight <= 768 && orientation === 'portrait') {
    return '250px'
  } else if (sHeight <= 768 && orientation === 'landscape') {
    return '450px'
  } else if (sHeight <= 768 && orientation === 'square') {
    return '400px'
  }

  if (sHeight <= 900 && orientation === 'portrait') {
    return '300px'
  } else if (sHeight <= 900 && orientation === 'landscape') {
    return '500px'
  } else if (sHeight <= 900 && orientation === 'square') {
    return '450px'
  }

  if (sHeight <= 1080 && orientation === 'portrait') {
    return '400px'
  } else if (sHeight <= 1080 && orientation === 'landscape') {
    return '550px'
  } else if (sHeight <= 1080 && orientation === 'square') {
    return '500px'
  }

  if (sHeight <= 1280 && orientation === 'portrait') {
    return '420px'
  } else if (sHeight <= 1280 && orientation === 'landscape') {
    return '650px'
  } else if (sHeight <= 1280 && orientation === 'square') {
    return '600px'
  }

  return '350px'
}

const ImageOverlay = ({ image, height }) => {
  return (
    <Styled.div
      sx={{
        width: '100%',
        maxWidth: getMaxWidth(height, image.fluid.aspectRatio),
        height: '100%',
        position: 'absolute',
        top: '0px',
        right: '0px',
        backgroundSize: 'cover',
        transition: 'background-color 1s',
        opacity: 0,
        ':hover': {
          opacity: 1,
          background: 'linear-gradient(180deg, rgba(0,0,0,0.6) 15%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.1) 60%)'
        },
        ':before': {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'inherit',
          content: '" "'
        }
      }}
    >
      <p
        sx={{
          width: 'auto',
          display: 'block',
          mt: '0.25rem',
          ml: '0.5rem',
          fontSize: '0.8rem',
          color: '#fff'
        }}
      >
        {image.title}
      </p>
    </Styled.div>
  )
}

const IllustrationTemplate = ({ data, location }) => {
  const { title, date, description, tags, images } = data?.contentfulIllustration
  const swiperRef = React.useRef(null)

  const breadcrumbItems = [
    { label: 'Works', slug: '/works' },
    { label: title, slug: '' }
  ]

  const currentBreakpoint = useCurrentBreakpoint()

  const size = useWindowSize()

  const { height } = size

  const [swiperState, dispatchSwiperState] = React.useReducer(reducer, {
    initialised: false,
    isBeginning: true,
    isEnd: false
  })

  const [active, setActive] = React.useState(null)

  const { initialised, isBeginning, isEnd } = swiperState

  const swiperParams = {
    Swiper,
    modules: [A11y, Lazy],
    ...(currentBreakpoint > smallBreakpoint && {
      slidesPerView: 'auto'
    }),
    lazy: {
      loadPrevNext: true
    },
    updateOnWindowResize: true,
    spaceBetween: 10,
    centerInsufficientSlides: images?.length > 1,
    grabCursor: images?.length > 1,
    breakpoints: {
      420: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: images?.length > 1 ? 2 : 1,
        spaceBetween: 30
      },
      1440: {
        slidesPerView: images?.length > 1 ? 3 : 2,
        spaceBetween: 40
      }
    },
    on: {
      init() {
        if (!initialised) {
          dispatchSwiperState({
            initialised: true,
            isBeginning: this.isBeginning,
            isEnd: this.isEnd
          })
        }
      },
      transitionEnd() {
        dispatchSwiperState({
          isBeginning: this.isBeginning,
          isEnd: this.isEnd
        })
      }
    }
  }

  const noArrows = isBeginning && isEnd

  if (!images?.length) {
    return null
  }

  const isWallpaper = tags.some(tag => tag?.toLowerCase() === 'wallpaper')

  const seoImage =
    images?.[0]?.large?.src &&
    `http:${images?.[0]?.large?.src
      ?.replace(/w=[\w]+/, 'w=1200')
      .replace(/h=[\w]+/, 'h=600')
      .replace('fit=fill', 'fit=crop')}`

  return (
    <Layout location={location}>
      <Seo title={title} description={description?.description} image={seoImage} cardType="summary_large_image" />

      <Breadcrumb items={breadcrumbItems} />

      <Styled.div>
        {!noArrows && (
          <Styled.div sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              sx={{ cursor: isBeginning ? 'text' : 'pointer' }}
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slidePrev()
                }
              }}
              disabled={isBeginning}
            >
              <LeftArrowIcon disabled={isBeginning} />
            </IconButton>

            <IconButton
              sx={{ cursor: isEnd ? 'cursor' : 'pointer' }}
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slideNext()
                }
              }}
              disabled={isEnd}
            >
              <RightArrowIcon disabled={isEnd} />
            </IconButton>
          </Styled.div>
        )}

        <ReactIdSwiperCustom {...swiperParams} ref={swiperRef}>
          {images.map(image => (
            <Styled.div
              key={image.id}
              sx={{
                width: '100%',
                maxWidth: getMaxWidth(height, image.fluid.aspectRatio)
              }}
              onClick={() =>
                setActive({
                  original: image.file.url,
                  large: image.large.src,
                  medium: image.large.src,
                  small: image.fluid.src
                })
              }
            >
              <ImageFluid
                image={image}
                alt={image.title}
                fadeIn={false}
                aspectRatio={image.fluid.aspectRatio}
                imgStyle={{ objectFit: 'contain' }}
                sx={{
                  width: '100%'
                }}
              />
              <ImageOverlay height={height} image={image} />
            </Styled.div>
          ))}
        </ReactIdSwiperCustom>
      </Styled.div>

      <Styled.h2 sx={{ m: 0, mt: '1.8rem', fontWeight: 400, fontSize: '1.1rem' }}>{title}</Styled.h2>
      <Styled.p sx={{ m: 0, fontWeight: 200, fontSize: '1rem' }}>{date}</Styled.p>

      {description?.childMdx?.body ? (
        <Styled.div sx={{ mt: 1, mb: 1, width: '100%', fontSize: '0.9rem', maxWidth: '700px' }}>
          <MdxField>{description?.childMdx?.body}</MdxField>
        </Styled.div>
      ) : null}

      <Styled.div sx={{ m: 0, fontWeight: 200, fontSize: '1rem' }}>
        {tags?.map(tag => (
          <Badge key={tag} variant="outline" mr={1}>
            {tag}
          </Badge>
        ))}
      </Styled.div>

      {active ? (
        <Lightbox
          small={active.small}
          medium={active.medium}
          large={isWallpaper ? active.original : active.large}
          hideDownload={!isWallpaper}
          hideZoom
          onClose={() => setActive(null)}
          className="image-modal"
        />
      ) : null}

      <Styled.div
        sx={{
          mt: '5rem',
          display: 'flex',
          maxWidth: '300px',
          alignSelf: 'center',
          justifyContent: 'start'
        }}
      >
        <SocialShare url={location.href} image={images?.[0]?.large?.src} />
      </Styled.div>

      <div sx={{ mt: '5rem' }}>
        <Footer />
      </div>
    </Layout>
  )
}

export default IllustrationTemplate

export const illustrationQuery = graphql`
  query IllustrationById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulIllustration(id: { eq: $id }) {
      id
      title
      description {
        childMdx {
          body
        }
      }
      tags
      date(formatString: "MMM YYYY")
      images {
        id
        ...FragmentIllustrationAsset
        fluid(maxWidth: 440, quality: 85, resizingBehavior: PAD) {
          ...FragmentImageFluid
        }
        large: fluid(maxWidth: 1280, maxHeight: 1600, quality: 85, resizingBehavior: FILL) {
          ...FragmentImageFluid
        }
      }
    }
  }
`
