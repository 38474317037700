import facepaint from 'facepaint'
import { useEffect, useState, useMemo } from 'react'

export const smallBreakpoint = 320
export const mediumBreakpoint = 768
export const largeBreakpoint = 1024
export const xlargeBreakpoint = 1440

export const breakpoints = [smallBreakpoint, mediumBreakpoint, largeBreakpoint, xlargeBreakpoint]

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

const getMedia = (index, formattedBreakpoints) => {
  const minWidth = `(min-width: ${formattedBreakpoints[index]}px)`
  const maxWidth =
    index < formattedBreakpoints.length - 1 ? `(max-width: ${formattedBreakpoints[index + 1] - 1}px)` : ''

  if (maxWidth) {
    return `${minWidth} and ${maxWidth}`
  }

  return minWidth
}

export const useCurrentBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(breakpoints[breakpoints.length - 1])
  const formattedBreakpoints = useMemo(() => [0, ...breakpoints], [])

  useEffect(() => {
    const mediaQueryLists = formattedBreakpoints.map((breakpoint, index) => {
      const handler = e => {
        if (e.matches) {
          setCurrentBreakpoint(breakpoint)
        }
      }

      const media = getMedia(index, formattedBreakpoints)
      const mql = window.matchMedia(media)

      mql.addListener(handler)

      if (mql.matches) {
        setCurrentBreakpoint(breakpoint)
      }

      return {
        mql,
        handler
      }
    })

    return () => {
      mediaQueryLists.forEach(({ mql, handler }) => {
        mql.removeListener(handler)
      })
    }
  }, [formattedBreakpoints])

  return currentBreakpoint
}

export default facepaint([
  `@media(min-width: ${smallBreakpoint}px)`,
  `@media(min-width: ${mediumBreakpoint}px)`,
  `@media(min-width: ${largeBreakpoint}px)`,
  `@media(min-width: ${xlargeBreakpoint}px)`
])
